<template>
  <Panel lineColor="var(--primary-color)" class="panel">
    <div class="container-panel">
      <div class="marginBottom">
        <h4 class="text-start">Controle de maioridade</h4>
        <LineDivider />

        URL da página que o usuário será redirecionado.
      </div>
      <FormField
        placeholder="Informe sua url"
        v-model="paginaControleMaioridade"
      />
      <div class="button">
        <FormButton
          text="Salvar"
          type="primario"
          :loading="loading"
          :disabled="loading"
          @click="onSubmitAgeControl"
        />
      </div>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/Panel'
import LineDivider from '@/components/LineDivider'
import FormField from '@/components/form/FormField'
import FormButton from '@/components/form/FormButton'
import { UPDATE_PAGE_AGE_CONTROL } from '@/store/actions/statusPedidos'

export default {
  components: {
    Panel,
    LineDivider,
    FormField,
    FormButton
  },
  props: {
    dados: {
      type: [String],
      default: '',
      required: false
    }
  },
  data() {
    return {
      loading: false,
      paginaControleMaioridade: null
    }
  },
  methods: {
    onSubmitAgeControl() {
      const validarUrl = this.validateURL()
      if (validarUrl) return this.$vueOnToast.pop('warning', validarUrl)

      this.loading = true
      this.$store
        .dispatch(UPDATE_PAGE_AGE_CONTROL, {
          paginaControleMaioridade: this.paginaControleMaioridade
        })
        .then(result => {
          const msg =
            result && result.Mensagem
              ? result.Mensagem
              : 'Configurações do parâmetros atualizados'
          this.$vueOnToast.pop('success', msg)
          this.loading = false
        })
        .catch(error => {
          this.loading = false
          const msg =
            error && error.Mensagem
              ? error.Mensagem
              : 'Erro ao salvar Configurações do parâmetros'

          const isSucesso = error && error.Sucesso ? error.Sucesso : false
          isSucesso
            ? this.$vueOnToast.pop('warning', msg)
            : this.$vueOnToast.pop('error', msg)
        })
    },
    validateURL() {
      const httpOrHttps = /^(https?:\/\/)/

      if (
        this.paginaControleMaioridade &&
        !httpOrHttps.test(this.paginaControleMaioridade)
      ) {
        return "Ops. Algo deu erro. A URL informada deve conter os seguintes protocolos 'http://' ou 'https://'"
      }
    }
  },
  watch: {
    dados: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.paginaControleMaioridade = newVal
        }
      }
    }
  }
}
</script>

<style scoped>
.panel {
  margin-top: 30px;
  text-align: left;
}

.panel .container-panel {
  padding: 0;
}

.marginBottom {
  margin-bottom: 30px;
}

.button {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}
</style>
