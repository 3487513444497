<template>
  <v-scroll-y-transition>
    <div v-if="resumoPedido.idpedido">
      <LineDivider />

      <label class="v-label theme--light">Resumo do pedido: </label>

      <ul style="list-style: none;">
        <li>
          <a
            :href="
              '/fornecedor/pedidos/pedido?numped=' + resumoPedido.numpedido
            "
            target="_newtab"
          >
            <b>Pedido</b> {{ resumoPedido.numpedido }} (clique para mais
            detalhes)
          </a>
        </li>
        <li><b>Data da compra: </b> {{ resumoPedido.formattedDate }}</li>
        <li><b>Cliente: </b> {{ resumoPedido.nome }}</li>
        <li><b>Total liquido: </b> R$ {{ resumoPedido.formattedTotal }}</li>
        <li v-if="'statusAtualText' in resumoPedido">
          <b>Status atual: </b> {{ resumoPedido.statusAtualText }}
        </li>
      </ul>

      <div v-if="'tipo' in tipo && tipo.tipo === 'alterarStatus'">
        <label class="v-label theme--light">Novo status do pedido: </label>
        <FormSelect
          placeholder="Selecione o novo status do pedido"
          style="margin-top: 10px; width: 330px;"
          :value="value"
          :options="options"
          :clearable="false"
          @input="onInput"
        />

        <v-btn
          color="error"
          @click="$emit('confirmar')"
          :loading="dados.loading"
          :disabled="dados.loading || !dados.pedidoPesquisado || !value"
        >
          Aplicar novo status
        </v-btn>
      </div>

      <div v-else>
        <v-btn
          color="error"
          @click="$emit('confirmar')"
          style="margin-top: 20px;"
          :loading="dados.loading"
          :disabled="!resumoPedido.idpedido || dados.loading"
        >
          Excluir permanentemente
        </v-btn>
      </div>
    </div>
  </v-scroll-y-transition>
</template>

<script>
import LineDivider from '@/components/LineDivider'
import FormSelect from '@/components/form/FormSelect'
import * as moment from 'moment'

export default {
  components: {
    LineDivider,
    FormSelect
  },
  data() {
    return {
      resumoPedido: {},
      options: []
    }
  },
  props: {
    tipo: Object,
    dados: Object,
    value: String
  },
  computed: {
    statusAtualText() {
      return this.resumoPedido && this.statusList
        ? this.resolveStatusName(this.resumoPedido.statuspedido)
        : 'Erro ao verificar status atual'
    },
    formattedDate() {
      if (this.resumoPedido && this.resumoPedido.datapedido) {
        return moment(this.resumoPedido.datapedido).format(
          'DD/MM/YYYY HH:mm:ss Z'
        )
      }

      return null
    },
    formattedTotal() {
      if (this.resumoPedido && this.resumoPedido.totalliquido) {
        return (Number(this.resumoPedido.totalliquido) || 0).toFixed(2)
      }

      return null
    },
    formattedDateExclusao() {
      if (this.resumoPedido && this.resumoPedido.datapedido) {
        return moment(this.resumoPedido.datapedido).format(
          'DD/MM/YYYY HH:mm:ss Z'
        )
      }

      return null
    },
    formattedTotalExclusao() {
      if (this.resumoPedido && this.resumoPedido.totalliquido) {
        return (Number(this.resumoPedido.totalliquido) || 0).toFixed(2)
      }

      return null
    }
  },
  watch: {
    tipo: {
      deep: true,
      handler(newVal) {
        switch (newVal.tipo) {
          case 'alterarStatus': {
            this.resumoPedido = newVal.value
            this.resumoPedido = {
              ...this.resumoPedido,
              formattedDate: this.formattedDate,
              formattedTotal: this.formattedTotal,
              statusAtualText: this.statusAtualText
            }

            const options = this.dados.options.slice()
            const index = options.findIndex(
              ({ text }) => text === this.statusAtualText
            )

            if (index > -1) {
              options.splice(index, 1)
              this.options = options
            }

            break
          }
          case 'excluirPedido': {
            this.resumoPedido = newVal.value
            this.resumoPedido = {
              ...this.resumoPedido,
              formattedDate: this.formattedDateExclusao,
              formattedTotal: this.formattedTotalExclusao
            }
            this.dados.options.splice(
              this.dados.options.findIndex(
                ({ text }) => text === this.statusAtualText
              ),
              1
            )
            break
          }
          default:
            break
        }
      }
    },

    dados: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.statusList = newVal.statusList
        }
      }
    }
  },
  methods: {
    resolveStatusName(statusCode) {
      return this.statusList.length && statusCode
        ? this.statusList.find(s => s.statusCode == statusCode).name
        : ''
    },
    onInput(value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style scoped>
label {
  font-size: 18px;
  font-weight: 600;
}

ul {
  list-style: none;
  margin: 10px 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}

b {
  color: #6a6c6f;
}

li:first-child > a > b {
  color: #34495e;
}
</style>
