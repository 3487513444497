<template>
  <Panel lineColor="var(--primary-color)" class="panel">
    <div class="container-panel">
      <div>
        <h4 class="text-start">{{ form.title }}</h4>
        <LineDivider />
        <div
          class="descricao"
          v-if="form.description"
          v-html="form.description"
        ></div>

        <div class="descricao">
          Esta operação
          <b style="color: #e74c3c;">
            {{ form.paragrafo ? form.paragrafo : 'é irreversível.' }}
          </b>
        </div>

        <FormSelect
          label="Gateway"
          placeholder="Selecione um gateway (opcional)"
          v-model="select"
          v-if="
            form.action === 'excluir-politicas' ||
              form.action === 'excluir-clientes'
          "
          :options="opcaoSelect[form.action]"
          :clearable="false"
        />

        <v-btn
          color="error"
          :disabled="!enablebutton"
          :loading="!enablebutton"
          @click="$emit('click', form.action)"
        >
          {{ form.buttonText }}
        </v-btn>
      </div>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/Panel'
import LineDivider from '@/components/LineDivider'
import FormSelect from '@/components/form/FormSelect'

export default {
  components: {
    Panel,
    LineDivider,
    FormSelect
  },
  props: {
    form: {
      type: Object,
      default: () => {
        return {}
      },
      required: true
    },
    enablebutton: {
      type: Boolean,
      default: true,
      required: false
    }
  },
  data() {
    return {
      opcaoSelect: {
        'excluir-politicas': [
          {
            text: 'Apenas políticas vencidas',
            value: { tipo: 'allPolicy', valor: false }
          },
          {
            text: 'Todas as políticas',
            value: { tipo: 'allPolicy', valor: true }
          }
        ],
        'excluir-clientes': [
          {
            text: 'Apenas clientes não aprovados',
            value: { tipo: 'allClientes', valor: false }
          },
          {
            text: 'Todos os clientes',
            value: { tipo: 'allClientes', valor: true }
          }
        ]
      },
      select: {}
    }
  },
  mounted() {
    const tipo = this.form.action === 'excluir-politicas'
    this.select = tipo
      ? { tipo: 'allPolicy', valor: false }
      : { tipo: 'allClientes', valor: false }
  },
  watch: {
    select: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          if (newVal.tipo === 'allPolicy' && this.form.paragrafo) {
            this.form.paragrafo = `apaga todas as políticas${
              !newVal.valor ? ' vencidas' : ''
            }.`
          } else if (newVal.tipo === 'allClientes' && this.form.paragrafo) {
            this.form.paragrafo = `apaga todos os clientes${
              !newVal.valor ? ' com cadastro não aprovado' : ''
            }`
          }

          this.$emit('input', newVal)
        }
      }
    }
  }
}
</script>

<style scoped>
.panel {
  margin-top: 30px;
  text-align: left;
}

.panel .container-panel {
  padding: 0;
}

.descricao {
  margin-bottom: 26px;
}
</style>
