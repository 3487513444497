<template>
  <Panel lineColor="var(--primary-color)" class="panel">
    <div class="container-panel">
      <h4 class="text-start">Excluir Pedido</h4>
      <LineDivider />

      Esta operação
      <b style="color: #e74c3c;">
        apaga o pedido do atendimento.
      </b>

      <br />

      Nenhuma mensagem é enviada ao cliente final nessa ação.

      <div class="excluirPedido">
        <FormField
          placeholder="Informe o número do pedido"
          v-model="deletaProduto.pesquisa"
        />

        <v-btn
          color="success"
          style="margin-left: 10px;"
          @click="buscarPedidoDelete"
          :loading="loadingBuscarPedido"
          :disabled="!deletaProduto.pesquisa || loadingBuscarPedido"
        >
          Buscar pedido
        </v-btn>
      </div>

      <ResumoPedidoManutencao
        :tipo="{ tipo: 'excluirPedido', value: deletaProduto.resumoPedido }"
        :dados="{
          loading: loadingExcluirPedido
        }"
        @confirmar="confirmExclusaoPedido"
      />
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/Panel'
import LineDivider from '@/components/LineDivider'
import FormField from '@/components/form/FormField'
import ResumoPedidoManutencao from '../resumo/ResumoPedidoManutencao'
import {
  OBTEM_PEDIDO_SIMPLES,
  SOFT_DELETE_PEDIDO
} from '@/store/actions/statusPedidos'

export default {
  components: {
    Panel,
    LineDivider,
    FormField,
    ResumoPedidoManutencao
  },
  data() {
    return {
      loadingBuscarPedido: false,
      deletaProduto: {
        enableButton: true,
        pesquisa: '',
        resumoPedido: {}
      },
      loadingExcluirPedido: false
    }
  },
  methods: {
    buscarPedidoDelete() {
      this.loadingBuscarPedido = true

      this.$store
        .dispatch(OBTEM_PEDIDO_SIMPLES, this.deletaProduto.pesquisa)
        .then(pedido => {
          if (pedido) {
            this.deletaProduto.resumoPedido = pedido
          } else {
            this.$vueOnToast.pop(
              'error',
              'Pedido não encontrado para esta loja.'
            )
          }
          this.loadingBuscarPedido = false
        })
        .catch(() => {
          this.loadingBuscarPedido = false
          this.$vueOnToast.pop('error', 'Pedido não encontrado para esta loja')
        })
    },
    confirmExclusaoPedido() {
      this.$swal
        .fire({
          title: `Deseja excluir o pedido número ${this.deletaProduto.pesquisa} permanentemente?`,
          text: 'Esta operação é irreversível. Continuar?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        })
        .then(async result => {
          if (result.value) {
            this.deletaProduto.enableButton = false
            this.executarExclusaoPedido(this.deletaProduto.pesquisa)
          } else {
            this.deletaProduto.enableButton = true
          }
        })
    },
    executarExclusaoPedido(numpedido) {
      this.loadingExcluirPedido = true
      if (!numpedido) return

      this.$store
        .dispatch(SOFT_DELETE_PEDIDO, numpedido)
        .then(() => {
          this.$vueOnToast.pop('success', 'Pedido removido')
          this.deletaProduto.resumoPedido = {}
          this.deletaProduto.pesquisa = ''
          this.deletaProduto.enableButton = true
          this.loadingExcluirPedido = false
        })
        .catch(() => {
          this.$vueOnToast.pop('error', 'Erro ao remover pedido')
          this.deletaProduto.enableButton = true
          this.loadingExcluirPedido = false
        })
    }
  }
}
</script>

<style scoped>
.panel {
  margin-top: 30px;
  text-align: left;
}

.panel .container-panel {
  padding: 0;
}

.excluirPedido {
  display: flex;
  margin-top: 30px;
}
</style>
