<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>

  <div v-else-if="showModuloForm === true">
    <LojaManutencao :dados="textoAvisoModoManutencao" />
    <StatusPedidoManutencao />
    <LimparIdPedidoManutencao />
    <ExcluirPedidoManutencao />
    <AcaoManutencao />
    <ControleMaioridadeManutencao :dados="paginaControleMaioridade" />
  </div>
</template>

<script>
import LojaManutencao from './loja/LojaManutencao'
import StatusPedidoManutencao from './status/StatusPedidoManutencao'
import LimparIdPedidoManutencao from './pedido/LimparIdPedidoManutencao'
import ExcluirPedidoManutencao from './pedido/ExcluirPedidoManutencao'
import AcaoManutencao from './produto/AcaoManutencao'
import ControleMaioridadeManutencao from './controle/ControleMaioridadeManutencao'
import ApiWeb from '../../../service/api-web'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import { mapGetters } from 'vuex'

export default {
  components: {
    LojaManutencao,
    StatusPedidoManutencao,
    LimparIdPedidoManutencao,
    ExcluirPedidoManutencao,
    AcaoManutencao,
    ControleMaioridadeManutencao
  },
  data() {
    return {
      modulosForm: [ModulesEnum.TODOS],
      textoAvisoModoManutencao: null,
      paginaControleMaioridade: null
    }
  },
  methods: {
    obtemDadosFornecedor() {
      ApiWeb.v1.fornecedor
        .obtemDadosFornecedor(this.getFornecedorId)
        .then(({ data }) => {
          this.textoAvisoModoManutencao = data.texto_aviso_modo_manutencao
          this.paginaControleMaioridade = data.pagina_controle_maioridade
        })
        .catch(() => {
          this.$vueOnToast.pop(
            'error',
            'Não foi possível obter os dados do fornecedor. Ocorreu um erro no servidor'
          )
        })
    }
  },
  computed: {
    ...mapGetters(['moduloFornecedorAtivo', 'getFornecedorId']),
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    }
  },
  mounted() {
    this.obtemDadosFornecedor()
  }
}
</script>
