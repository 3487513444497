<template>
  <Panel lineColor="var(--primary-color)" class="panel">
    <div class="container-panel">
      <h4 class="text-start">Limpar ID ERP do Pedido</h4>
      <LineDivider />

      Esta operação
      <b style="color: #e74c3c;">
        anula o id ERP do pedido
      </b>

      <br />

      Nenhuma mensagem de chat é enviada ao cliente final nessa ação.

      <div class="limparID">
        <FormField
          placeholder="Informe o id pedido erp"
          v-model="idPedidoERP"
        />

        <v-btn
          color="success"
          style="margin-left: 10px;"
          @click="limparIdPedidoERP"
          :loading="loadingLimparIdPedido"
          :disabled="!idPedidoERP || loadingLimparIdPedido"
        >
          Limpar ID Pedido ERP
        </v-btn>
      </div>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/Panel'
import LineDivider from '@/components/LineDivider'
import FormField from '@/components/form/FormField'
import { LIMPAR_ID_PEDIDO_ERP } from '@/store/actions/statusPedidos'
import * as _ from 'lodash'

export default {
  components: {
    Panel,
    LineDivider,
    FormField
  },
  data() {
    return {
      idPedidoERP: '',
      loadingLimparIdPedido: false
    }
  },
  methods: {
    limparIdPedidoERP() {
      this.loadingLimparIdPedido = true
      this.$store
        .dispatch(LIMPAR_ID_PEDIDO_ERP, this.idPedidoERP)
        .then(({ data }) => {
          const produtoExistente = _.get(data, 'produtoexistente', false)
          const produtoIntegrado = _.get(data, 'produtointegrado', false)

          if (produtoExistente) {
            if (produtoIntegrado) {
              this.$vueOnToast.pop(
                'success',
                'Id do pedido ERP foi anulado com sucesso!'
              )
            } else {
              this.$vueOnToast.pop(
                'warning',
                `Não foi possível prosseguir com a anulação do ID ERP do pedido. Pedido não Integrado.`
              )
            }
          } else {
            this.$vueOnToast.pop(
              'warning',
              `Não foi possível prosseguir com a anulação do ID ERP do pedido. Nenhum pedido com número: ${this.idPedidoERP} foi encontrado`
            )
          }
          this.loadingLimparIdPedido = false
        })
        .catch(() => {
          this.loadingLimparIdPedido = false
          this.$vueOnToast.pop('error', 'Pedido não encontrado para esta loja')
        })
    }
  }
}
</script>

<style scoped>
.panel {
  margin-top: 30px;
  text-align: left;
}

.panel .container-panel {
  padding: 0;
}

.limparID {
  display: flex;
  margin-top: 30px;
}
</style>
