<template>
  <div>
    <ExclusaoManutencao
      v-for="(form, index) in forms"
      v-model="select"
      :key="index"
      :form="form"
      :enablebutton="enableButton"
      @click="acaoBotao"
    />
  </div>
</template>

<script>
import ExclusaoManutencao from './ExclusaoManutencao'
import {
  DESABILITAR_MIX,
  REMOVE_PRODUCTS,
  REMOVE_IMAGE_HISTORY,
  REMOVER_POLITICAS,
  REMOVER_CLIENTES
} from '@/store/actions/statusPedidos'
import * as _ from 'lodash'

export default {
  components: {
    ExclusaoManutencao
  },
  data() {
    return {
      forms: [
        {
          title: 'Excluir precificação',
          description: this.getDescription('precos'),
          paragrafo: null,
          buttonText: 'Excluir todos os meus preços',
          action: 'excluir-precos'
        },
        {
          title: 'Excluir produtos',
          description: this.getDescription('produtos'),
          paragrafo: null,
          buttonText: 'Excluir todos os meus produtos',
          action: 'excluir-produtos'
        },
        {
          title: 'Excluir histórico de Imagens',
          description: this.getDescription('historicoImagens'),
          paragrafo: null,
          buttonText: 'Excluir histórico de importação de imagens',
          action: 'excluir-historico-imagens'
        },
        {
          title: 'Excluir políticas de desconto',
          description: null,
          paragrafo: 'apaga todas as políticas vencidas.',
          buttonText: 'Excluir permanentemente',
          action: 'excluir-politicas'
        },
        {
          title: 'Excluir clientes',
          description: null,
          paragrafo: 'apaga todos os clientes com cadastro não aprovado.',
          buttonText: 'Excluir permanentemente',
          action: 'excluir-clientes'
        }
      ],
      select: {},
      enableButton: true,
      allPolicy: false,
      allClientes: false
    }
  },
  methods: {
    getDescription(type) {
      switch (type) {
        case 'precos':
          return (
            'Esta operação remove os preços de todos os produtos do mix desta loja.' +
            '<br/>' +
            'Nenhum produto estará disponivel para venda depois da execução.'
          )
        case 'produtos':
          return 'Esta operação irá apagar todos os produtos, suas fotos e descrições desta loja.'
        case 'historicoImagens':
          return (
            'Esta operação irá apagar o histórico de importação de imagens desta loja.' +
            '<br/>' +
            'Nenhuma imagem será excluída apenas o histórico de importação da mesma.'
          )
      }
    },
    acaoBotao(teste) {
      const acao = {
        'excluir-precos': () =>
          this.confirmAction(() => this.doExcluirPrecos()),
        'excluir-produtos': () =>
          this.confirmAction(() => this.doExcluirProdutos()),
        'excluir-historico-imagens': () =>
          this.confirmAction(() => this.doExcluirHistoricoImagens()),
        'excluir-politicas': () =>
          this.confirmAction(() => this.doExcluirPoliticas()),
        'excluir-clientes': () =>
          this.confirmAction(() => this.doExcluirClientes())
      }

      acao[teste]()
    },
    confirmAction(callback = () => {}) {
      this.$swal
        .fire({
          title: 'Certeza?',
          text: 'Esta operação é irreversível. Continuar?',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        })
        .then(async result => {
          if (result.value) {
            this.enableButton = false
            callback()
          } else {
            this.enableButton = true
          }
        })
    },
    doExcluirPrecos() {
      this.$store
        .dispatch(DESABILITAR_MIX)
        .then(affected => {
          this.$vueOnToast.pop(
            'success',
            `${affected.affected} produtos afetados Operação realizada`
          )
          this.enableButton = true
        })
        .catch(error => {
          this.$vueOnToast.pop('error', error)
          this.enableButton = true
        })
    },
    doExcluirProdutos() {
      this.$store
        .dispatch(REMOVE_PRODUCTS)
        .then(result => {
          const msg =
            _.get(result, 'message') ||
            'Operação em andamento, a exclusão dos produtos pode levar um tempo.'
          this.$vueOnToast.pop('success', msg)
          this.enableButton = true
        })
        .catch(() => {
          this.$vueOnToast.pop('error', 'Erro ao tentar excluir produtos')
          this.enableButton = true
        })
    },
    doExcluirHistoricoImagens() {
      this.$store
        .dispatch(REMOVE_IMAGE_HISTORY)
        .then(result => {
          const msg =
            _.get(result, 'message') ||
            'Histórico de importação de imagens excluído.'
          this.$vueOnToast.pop('success', msg)
          this.enableButton = true
        })
        .catch(() => {
          this.$vueOnToast.pop(
            'error',
            'Erro ao tentar excluir histórico de importação de imagens'
          )
          this.enableButton = true
        })
    },
    doExcluirPoliticas() {
      this.$store
        .dispatch(REMOVER_POLITICAS, this.allPolicy)
        .then(result => {
          const erro = _.get(result, 'erro', 200)
          const msg =
            _.get(result, 'message') ||
            'Operação em andamento, a exclusão das políticas de desconto pode levar um tempo.'

          if (erro === 404) {
            this.$vueOnToast.pop('warning', msg)
          } else {
            this.$vueOnToast.pop('success', msg)
          }

          this.enableButton = true
        })
        .catch(() => {
          this.$vueOnToast.pop(
            'error',
            'Erro ao tentar excluir políticas de desconto'
          )
          this.enableButton = true
        })
    },
    doExcluirClientes() {
      this.$store
        .dispatch(REMOVER_CLIENTES, this.allClientes)
        .then(result => {
          const msg =
            _.get(result, 'message') ||
            'Operação em andamento, a exclusão dos clientes pode levar um tempo.'
          this.$vueOnToast.pop('success', msg)
          this.enableButton = true
        })
        .catch(() => {
          this.$vueOnToast.pop('error', 'Erro ao tentar excluir clientes')
          this.enableButton = true
        })
    }
  },
  watch: {
    select: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this[newVal.tipo] = newVal.valor
        }
      }
    }
  }
}
</script>

<style scoped>
h2 {
  text-transform: uppercase;
  font-size: 18px;
}

.panel {
  margin-top: 30px;
  text-align: left;
}

.panel .container {
  padding: 0;
  width: 97%;
}
</style>
