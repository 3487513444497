<template>
  <Panel lineColor="var(--primary-color)" class="panel">
    <div class="container-panel">
      <div class="marginBottom">
        <h4 class="text-start">Alterar status do pedido</h4>
        <LineDivider />

        Esta operação
        <b style="color: #e74c3c;">
          afeta o status de atendimento de um pedido.
        </b>

        <br />

        Nenhuma mensagem de chat é enviada ao cliente final nessa ação.
      </div>

      <div class="buscarPedido">
        <FormField
          placeholder="Informe o número do pedido"
          v-model="pedidoPesquisado"
        />

        <v-btn
          color="success"
          style="margin-left: 10px;"
          @click="buscarPedido"
          @keyup="clearPedidoPesquisado"
          :loading="loadingBuscarPedido"
          :disabled="!pedidoPesquisado || loadingBuscarPedido"
        >
          Buscar pedido
        </v-btn>
      </div>

      <ResumoPedidoManutencao
        v-model="novoStatus"
        :dados="{
          options: novoStatusPedido,
          loading: loadingMudancaStatus,
          pedidoPesquisado: pedidoPesquisado,
          statusList: statusList
        }"
        :tipo="{ tipo: 'alterarStatus', value: resumoPedido }"
        @confirmar="confirmarMudancaStatus"
      />
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/Panel'
import LineDivider from '@/components/LineDivider'
import FormField from '@/components/form/FormField'
import ResumoPedidoManutencao from '../resumo/ResumoPedidoManutencao'
import {
  OBTEM_PEDIDO_SIMPLES,
  BUSCAR_STATUS_PEDIDO,
  SET_STATUS_PEDIDO
} from '@/store/actions/statusPedidos'

export default {
  components: {
    Panel,
    LineDivider,
    FormField,
    ResumoPedidoManutencao
  },
  data() {
    return {
      pedidoPesquisado: '',
      resumoPedido: {},
      statusList: [],
      novoStatus: '',
      novoStatusPedido: [],
      loadingBuscarPedido: false,
      loadingMudancaStatus: false
    }
  },
  methods: {
    resolveStatusName(statusCode) {
      return this.statusList.length
        ? this.statusList.find(s => s.statusCode == statusCode).name
        : ''
    },
    clearPedidoPesquisado() {
      this.resumoPedido = {}
      this.novoStatus = ''
    },
    buscarPedido() {
      this.loadingBuscarPedido = true

      this.$store
        .dispatch(OBTEM_PEDIDO_SIMPLES, this.pedidoPesquisado)
        .then(async pedido => {
          if (!pedido)
            return this.$vueOnToast.pop(
              'warning',
              'Pedido não encontrado para esta loja.'
            )
          this.resumoPedido = pedido
          this.loadingBuscarPedido = false
        })
        .catch(() => {
          this.loadingBuscarPedido = false
          this.$vueOnToast.pop('error', 'Pedido não encontrado para esta loja')
        })
    },
    buscarStatusPedido() {
      this.$store.dispatch(BUSCAR_STATUS_PEDIDO).then(statusList => {
        this.novoStatusPedido = []

        statusList.forEach(status => {
          this.novoStatusPedido.push({
            text: status.name,
            value: status.statusCode
          })
        })
        this.statusList = statusList
      })
    },
    confirmarMudancaStatus() {
      if (!this.novoStatus) {
        return this.$vueOnToast.pop(
          'warning',
          'Selecione um novo status para o pedido'
        )
      }

      const statusEscolhido = this.resolveStatusName(this.novoStatus)

      this.loadingMudancaStatus = true
      this.$swal
        .fire({
          title: `Confirma a mudança do status do pedido ${this.pedidoPesquisado} para ${statusEscolhido}?`,
          text:
            'Essa operação afeta o fluxo de atendimento. Confira todas as informações antes de concluir.',
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Confirmar',
          cancelButtonText: 'Cancelar',
          reverseButtons: true
        })
        .then(async result => {
          if (result.value) {
            this.$store
              .dispatch(SET_STATUS_PEDIDO, {
                numpedido: this.pedidoPesquisado,
                newStatus: this.novoStatus
              })
              .then(() => {
                this.$vueOnToast.pop(
                  'success',
                  'Novo status aplicado com sucesso'
                )
                this.loadingMudancaStatus = false
                this.resumoPedido = {}
              })
              .catch(() => {
                this.$vueOnToast.pop(
                  'error',
                  'Erro ao atualizar o status do pedido manualmente'
                )
                this.loadingMudancaStatus = false
              })
          } else {
            this.loadingMudancaStatus = false
          }
        })
    }
  },
  created() {
    this.buscarStatusPedido()
  }
}
</script>

<style scoped>
label {
  font-size: 18px;
  font-weight: 600;
}

ul {
  list-style: none;
  margin: 10px 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}

b {
  color: #6a6c6f;
}

li:first-child > a > b {
  color: #34495e;
}

.panel {
  margin-top: 30px;
  text-align: left;
}

.panel .container-panel {
  padding: 0;
}

.buscarPedido {
  display: flex;
}

.marginBottom {
  margin-bottom: 30px;
}
</style>
