<template>
  <Panel lineColor="var(--primary-color)" class="panel">
    <div class="container-panel-panel">
      <h4 class="text-start">Loja em Manutenção</h4>
      <LineDivider />

      Esse modo
      <b style="color: #e74c3c;">
        ativará a opção de inacessibilidade ao cliente final a loja caso esteja
        ativado
      </b>

      <Panel
        lineColor="var(--primary-color)"
        style="margin-bottom: 30px;"
        v-if="filteredTags"
      >
        <div class="opcoes">
          <FormCheckbox
            v-for="tag in filteredTags"
            :key="tag.id"
            v-model="tag.active"
            :label="tag.description"
          />
        </div>
      </Panel>

      <FormText
        label="Escreva a mensagem que seu cliente verá quando o modo estiver habilitado"
        v-model="textoAvisoModoManutencao"
        :rows="10"
      />

      <div class="button">
        <FormButton
          text="Salvar"
          type="primario"
          :loading="loading"
          :disabled="loading"
          @click="onSubmit"
        />
      </div>
    </div>
  </Panel>
</template>

<script>
import Panel from '@/components/Panel'
import LineDivider from '@/components/LineDivider'
import FormCheckbox from '@/components/form/FormCheckbox'
import FormText from '@/components/form/FormText'
import FormButton from '@/components/form/FormButton'
import { PARAMETRO_LOAD_TAGS } from '@/store/actions/parametros'
import { SUBMIT_TAGS } from '@/store/actions/statusPedidos'

export default {
  components: {
    Panel,
    LineDivider,
    FormCheckbox,
    FormText,
    FormButton
  },
  props: {
    dados: {
      type: [String],
      default: '',
      required: false
    }
  },
  data() {
    return {
      tags: [],
      textoAvisoModoManutencao: null,
      loading: false
    }
  },
  methods: {
    carregarTags() {
      this.$store
        .dispatch(PARAMETRO_LOAD_TAGS, {
          onlyAdmin: '',
          onlyAdvancedMaintenance: ''
        })
        .then(tags => {
          if (tags) {
            this.tags = tags
          }
        })
    },
    normalizeDataToSubmit() {
      return {
        textoAvisoModoManutencao: this.textoAvisoModoManutencao,
        tags: this.tags.filter(t => t.active).map(t => t.id)
      }
    },
    onSubmit() {
      const data = this.normalizeDataToSubmit()

      this.loading = true
      this.$store
        .dispatch(SUBMIT_TAGS, data)
        .then(result => {
          this.loading = false

          const msg =
            result && result.Mensagem
              ? result.Mensagem
              : 'Configurações do parâmetros atualizados'
          this.$vueOnToast.pop('success', msg)
        })
        .catch(error => {
          this.loading = false
          const msg =
            error && error.Mensagem
              ? error.Mensagem
              : 'Erro ao salvar Configurações do parâmetros'

          const isSucesso = error && error.Sucesso ? error.Sucesso : false
          isSucesso
            ? this.$vueOnToast.pop('warning', msg)
            : this.$vueOnToast.pop('error', msg)
        })
    }
  },
  mounted() {
    this.carregarTags()
  },
  computed: {
    filteredTags() {
      return this.tags.filter(tag => tag.onlyAdvancedMaintenance)
    }
  },
  watch: {
    dados: {
      deep: true,
      handler(newVal) {
        if (newVal) {
          this.textoAvisoModoManutencao = newVal
        }
      }
    }
  }
}
</script>

<style scoped>
.panel {
  margin-top: 30px;
  text-align: left;
}

.panel .container-panel {
  padding: 0;
}

.opcoes {
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
  padding-right: 40px;
}

.button {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 10px;
}
</style>
